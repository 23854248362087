import api from "./api";
import clienteServices from './ClienteServices';

const OrdenTrabajoTermporalService = {};

let ODTT = {
    "tmpot0_codigo": 0,
    "tmpot0_sucurs": 0,
    "tmpot0_numero": 0,
    "tmpot0_fecemi": "Sin definir",
    "tmpot0_fecven": "Sin definir",
    "tmpot0_codcli": 0,
    "tmpot0_codven": 0,
    "tmpot0_descab": 0,
    "tmpot0_imptot": 0,
    "tmpot0_observ": "Sin definir",
    "tmpot0_estado": 0,
    "tmpot0_conpre": 0,
    "tmpot0_resstk": 0,
    "tmpot0_codli0": 0,
    "items": [{
        "tmpot1_codigo": 0,
        "tmpot1_item": 0,
        "tmpot1_codart": 0,
        "tmpot1_cantid": 0,
        "tmpot1_almace": 0,
        "tmpot1_pordes": 0,
        "tmpot1_descri": "Sin definir",
        "tmpot1_poriva": 0,
        "tmpot1_precio": 0,
        "precio_subtotal": 0
    }]
};

OrdenTrabajoTermporalService.ODTT = ODTT;

OrdenTrabajoTermporalService.get = async function() {
    return api.get('/OrdenesTrabajoTemp/').then(res => res.data);
}
OrdenTrabajoTermporalService.getById = async function(id) {
    return api.get('/OrdenesTrabajoTemp/' + id).then(res => res.data);
}
OrdenTrabajoTermporalService.addItem = async function(ID_articulo, cantidad) {
    return api.post('/OrdenesTrabajoTemp/addItem', {
        ID_articulo,
        cantidad,
        ID_cliente: clienteServices.getCodigo(),
    }).then(res => res.data);
}

OrdenTrabajoTermporalService.deleteItem = async function(ID_item) {
    //axios no manda el content-type en el delete, por lo que con el truquito de poner data:{...} se soluciona
    return api.delete('/OrdenesTrabajoTemp', {
        data: {
            ID_item,
            ID_cliente: clienteServices.getCodigo()
        }
    }).then(res => res.data);
}

OrdenTrabajoTermporalService.updateItem = async function(ID_item, cantidad) {
    return api.put('/OrdenesTrabajoTemp/modItem', {
        ID_item,
        cantidad,
        ID_cliente: clienteServices.getCodigo(),
    }).then(res => res.data);
}

OrdenTrabajoTermporalService.modObservacion = async function(observacion) {
    return api.put('/OrdenesTrabajoTemp/modObservacion', {
        observacion,
        ID_cliente: clienteServices.getCodigo(),
    }).then(res => res.data);
}

OrdenTrabajoTermporalService.confirmar = async function(ID_OTT) {
    return api.post('/OrdenesTrabajoTemp/confirmar', { 
        ID_OTT,
        ID_cliente: clienteServices.getCodigo(),
    }).then(res => res.data);
}

export default OrdenTrabajoTermporalService;