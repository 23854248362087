import api from "./api";
import userService from "./UserServices";

const clienteService = {};
let Cliente = {
    cli_codigo: -1,
    cli_cuit: 0,
    cli_codpos: 0,
    cli_codpde: 0,
    cli_tipcui: 0,
    cli_codtcv: 0,
    cli_razsoc: "Sin definir",
    cli_telefo: "Sin definir",
    cli_email: "Sin definir",
    cli_direcc: "Sin definir",
    tcu_sigla: "Sin definir",
    tcu_descri: "Sin definir",
    tiv_Descri: "Sin definir",
    loc_descri: "Sin definir"
};

clienteService.current = function() {
    let aux = JSON.parse(userService.current().Cliente);
    if (aux !== null && typeof aux === "object") {
        return aux;
    } else if (localStorage.getItem("cliente")) {
        return JSON.parse(localStorage.cliente);
    }
    return Cliente;
};

clienteService.getCodigo = function() {
    return clienteService.current().cli_codigo;
};

clienteService.setCliente = async function(cliente) {
    if (cliente !== null && typeof cliente === "object") {
        //si es un objeto cliente
        localStorage.cliente = JSON.stringify(cliente);
    } else {
        //si paso el codigo lo busco
        //Cliente.cli_codigo = cliente;
        //localStorage.cliente = JSON.stringify(Cliente);
        return api.get(`/cliente/${cliente}`)
        .then(res => {
            localStorage.cliente = JSON.stringify(res.data);
        });
    }
};
clienteService.unsetCliente = function(){
    localStorage.removeItem("cliente");
}

clienteService.getAll = async function() {
    return api.get("/cliente/").then(res => res.data);
};

clienteService.getbyID = async function(id) {
    return api.get(`/cliente/${id}`).then(res => res.data);
}

export default clienteService;