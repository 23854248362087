import '@babel/polyfill'
import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import EventBus from './plugins/event-bus'

Vue.config.productionTip = false
Vue.use(EventBus)

new Vue({
   router,
   render: h => h(App)
}).$mount('#app')