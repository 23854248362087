<template>
    <v-toolbar color="primary" fixed dark app>
        <v-toolbar-side-icon @click="handleDrawerToggle"></v-toolbar-side-icon>
        <v-spacer></v-spacer>
        <v-toolbar-items>
            <v-btn icon large flat slot="activator" to="/Pedidos/confirmar">
                <v-badge left overlap color="cyan">
                    <template v-slot:badge v-if="getCantidad > 0">
                        <span>{{ getCantidad }}</span>
                    </template>
                    <v-icon
                        :color="color"
                    >
                        shopping_cart
                    </v-icon>
                </v-badge>
            </v-btn>
            
            <v-menu offset-y origin="center" :nudge-bottom="10" transition="scale-transition">
                <v-btn icon large flat slot="activator">
                    <v-avatar size="30px">
                        <v-icon>account_circle</v-icon>
                    </v-avatar>
                </v-btn>
                <v-list class="pa-0">
                    <v-list-tile to="/micuenta">
                        <v-list-tile-action>
                            <v-icon>perm_identity</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>Mi Cuenta</v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile @click="logout()">
                        <v-list-tile-action>
                            <v-icon>fullscreen_exit</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>Salir</v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </v-list>
            </v-menu>
        </v-toolbar-items>
    </v-toolbar>
</template>

<script>
import UserService from '@/services/UserServices'
import ODTTServices from '@/services/OrdenesTrabajoTemServices'
export default {
    name: 'app-toolbar',
    data() {
        return {
            color: 'black',
            title: 'No hay pedidos en curso',
            cantidad: null,
        }
    },
    computed: {
        getCantidad() {
            return this.cantidad;
        }
    },
    created() {
        if(!UserService.IsAdmin()){
            this.ExisteODTT();
        }
    },
    methods: {
        logout() {
            UserService.logout();
            this.$router.push('/login');
        },
        handleDrawerToggle () {
            this.$bus.$emit('toggleNavigationDrawer');
        },
        colorearCarrito () {
            this.$bus.$on("setCar", () => {
                this.cantidad = (localStorage.cantidadItemsCarrito) ? localStorage.cantidadItemsCarrito : 0;
                this.color = 'white';
                this.title = 'Pedido en curso, confirmar pedido';
                this.$bus.$emit("PedidoNuevoContinuar", 'Continuar');
            });
            this.$bus.$on("cleanCar", () => {
                localStorage.removeItem('cantidadItemsCarrito');
                this.cantidad = null;
                this.color = 'black';
                this.title = 'No hay pedidos en curso';
                this.$bus.$emit("PedidoNuevoContinuar", 'Nuevo');
            });
        },
        ExisteODTT() {
            ODTTServices.get()
            .then(res => {
                let aux = 0;
                if (res.items.length > 0) {
                    for (let index = 0; index < res.items.length; index++) {
                        aux += parseFloat(res.items[index].tmpot1_cantid);
                    }
                    localStorage.cantidadItemsCarrito = parseFloat(aux);
                    this.$bus.$emit("setCar");
                }
            });
            this.colorearCarrito();
        },
    }
}
</script>

