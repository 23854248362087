import userService from "@/services/UserServices";

const UserMixin = {
  methods: {
    autenticado() {
      return userService.autenticado();
    }
  }
}

export default UserMixin