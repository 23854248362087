<template>
  <v-app id="app-container">
    <div v-if="autenticado()">
      <v-app id="inspire" class="app">
        <Aside class="app--drawer"></Aside>
        <Header class="app--toolbar"></Header>
        <v-content>
          <div class="page-wrapper">
            <router-view></router-view>
          </div>
        </v-content>
        <!-- Go to top -->
        <Go-Top></Go-Top>
      </v-app>
    </div>
    <transition v-else>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </transition>
  </v-app>
</template>

<script>
import UserMixin from '@/mixins/UserMixin'
import Header from '@/components/share/Header.vue'
import Aside from '@/components/share/Aside.vue'
import GoTop from '@/components/share/GoTop.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/assets/main.css'

export default {
  name: "App",
  mixins: [UserMixin],
  components: {
    Header,
    Aside,
    GoTop
  },
};
</script>


